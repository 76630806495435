import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import FileData from '../../models/FileData';

const DownloadFileDropdownItem: React.FC<PropsWithChildren<Props>> = ({
  downloadFile,
  onLoadingChange,
  onError,
  children,
}) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [file, setFile] = useState<{ url: string; name: string }>();

  const download = async () => {
    onLoadingChange?.(true);
    try {
      if (file) {
        URL.revokeObjectURL(file.url);
      }

      const fileData = await downloadFile();
      const fileUrl = URL.createObjectURL(fileData.data);
      setFile({ url: fileUrl, name: fileData.name });
    } catch (error) {
      onError?.(error);
    } finally {
      onLoadingChange?.(false);
    }
  };

  useEffect(() => {
    return () => {
      if (file) {
        URL.revokeObjectURL(file.url);
      }
    };
  }, []);

  useEffect(() => {
    if (file) {
      ref.current?.click();
    }
  }, [file]);

  return (
    <>
      <a href={file?.url} download={file?.name} className="d-none" ref={ref}>
        Hidden
      </a>
      <Dropdown.Item className="text-uppercase d-flex align-items-center" onClick={download}>
        {children}
      </Dropdown.Item>
    </>
  );
};

interface Props {
  downloadFile(): Promise<FileData>;
  onLoadingChange?(isLoading: boolean): void;
  onError?(error: unknown): void;
}

export default DownloadFileDropdownItem;
