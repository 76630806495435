import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import ListFileUploadSortColumn from '../../models/FileUpload/ListFileUploadSortColumn';
import SkeletonColors from '../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../models/Skeletons/SkeletonSpeed';
import './FileUploadList.scss';

const FileUploadListSkeleton: FC<Props> = ({ cards, columns }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  return (
    <>
      <div className="bg-color-gray-l3 text-uppercase p-3 rounded-top font-bold" data-test-id="loader">
        <FormattedMessage id="admin.fileManagement.totalCount" values={{ count: null }} />
        <ContentLoader
          height={20}
          speed={SkeletonSpeed}
          backgroundColor={SkeletonColors.lightGrey1}
          foregroundColor={SkeletonColors.lightGrey2}
          viewBox="0 0 70 25"
        >
          <rect x="0" y="0" rx="4" ry="4" width="40" height="20" />
        </ContentLoader>
      </div>
      <table className="file-upload-list-table w-100">
        <thead className="text-color-gray-l6">
          <tr className="size-caption">
            {columns.map((x) => (
              <th key={x.name} className="font-medium">
                <div className={`th-width-file-${x.name}`}>
                  <Button variant="link" className="text-color-gray-l6 btn-sort">
                    {x.key && <FormattedMessage id={x.key} />}{' '}
                  </Button>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="animated-component-skeleton">
          {items.map((key) => (
            <tr key={key}>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 300 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="200" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 190 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="80" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 196 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="120" height="25" />
                </ContentLoader>
              </td>

              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 196 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="130" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 120 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="90" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 210 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="130" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 114 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="90" height="25" />
                </ContentLoader>
              </td>
              <td align="center">
                <Button className="btn-toggle btn-secondary">
                  <FontAwesomeIcon icon={faEllipsis} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

interface Props {
  cards: number;
  columns: { name: ListFileUploadSortColumn | ''; key: string }[];
}
export default FileUploadListSkeleton;
