import appConfig from '../../appConfig';
import NavigationItem from '../../models/AppGallery/Module/NavigationItem';
import request from '../requestService';

class ModuleService {
  private readonly BaseUrl = `/Module`;

  public getNavigation = async (): Promise<{ navigations: NavigationItem[]; modules: string[] }> => {
    const response = await request.get<{ navigations: NavigationItem[]; modules: { name: string }[] }>(
      `${this.BaseUrl}/GetNavigation`,
      {
        baseURL: appConfig.appGalleryBackendUrl,
      },
    );

    return { navigations: response.data.navigations, modules: response.data.modules.map((x) => x.name) };
  };
}

export default new ModuleService();
