import FileData from '../models/FileData';
import CampaignFileList from '../models/FileUpload/CampaignFileList';
import CampaignFileType from '../models/FileUpload/CampaignFileType';
import PagingInfo from '../models/PagingInfo';
import request from './requestService';

class CampaignService {
  private readonly BaseUrl = `/CampaignFile`;

  private readonly BaseManufacturerUrl = `/Manufacturer/CampaignFile`;

  public async list(params: {
    orderBy?: string;
    paging?: PagingInfo;
    companyId?: string;
    fileStatus?: string;
  }): Promise<CampaignFileList> {
    const response = await request.get(`${this.BaseUrl}/List/`, {
      params: {
        orderBy: params.orderBy,
        ...params.paging,
        companyId: params.companyId,
        status: params.fileStatus,
      },
    });
    return response.data;
  }

  public async download(fileId: string): Promise<FileData> {
    const response = await request.get(`${this.BaseUrl}/Download`, {
      params: {
        fileId,
      },
      responseType: 'blob',
    });
    const filename = response.headers['content-disposition'].split("filename*=UTF-8''")[1].split(';')[0];
    return { data: response.data, name: decodeURI(filename) };
  }

  public markImported(fileId: string): Promise<void> {
    return request.post(`${this.BaseUrl}/MarkImported`, {
      fileId,
    });
  }

  public async manufacturerList(params: { orderBy?: string; paging?: PagingInfo }): Promise<CampaignFileList> {
    const response = await request.get(`${this.BaseManufacturerUrl}/List/`, {
      params: {
        orderBy: params.orderBy,
        ...params.paging,
      },
    });
    return response.data;
  }

  public async upload(
    campaignId: string,
    fileType: CampaignFileType,
    file: FileList,
    comment: string | null,
  ): Promise<void> {
    const formData = new FormData();
    formData.append('file', file[0], file[0].name);
    formData.append('campaignId', campaignId);
    formData.append('fileType', fileType);
    if (comment) {
      formData.append('comment', comment);
    }
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    await request.post(`${this.BaseManufacturerUrl}/Upload`, formData, options);
  }

  public async manufacturerDownload(fileId: string): Promise<FileData> {
    const response = await request.get(`${this.BaseManufacturerUrl}/Download`, {
      params: {
        fileId,
      },
      responseType: 'blob',
    });
    const filename = response.headers['content-disposition'].split("filename*=UTF-8''")[1].split(';')[0];
    return { data: response.data, name: decodeURI(filename) };
  }

  public remove(fileId: string): Promise<void> {
    return request.delete(`${this.BaseManufacturerUrl}/Remove/?fileId=${fileId}`);
  }
}

export default new CampaignService();
