import React, { ChangeEventHandler, FC, useRef, useState } from 'react';

import './FileUpload.scss';

import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faFile } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

const FileUpload: FC<Props> = ({ onChange, onClear, className, accept }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [fileName, setFileName] = useState<string>();
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFileName(event.target.files?.[0].name);
    onChange(event.target.files);
  };
  const clearFile = () => {
    setFileName(undefined);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    onClear?.();
  };

  return (
    <div className={`file-upload-component p-4 ${className}`}>
      <Form.Control type="file" ref={inputRef} accept={accept} onChange={handleChange} style={{ display: 'none' }} />
      {fileName ? (
        <div className="flex-column w-100">
          <div className="d-flex mb-3 align-items-center justify-content-center ">
            <FontAwesomeIcon icon={faFile} className="me-2" />
            <span className="file-name">{fileName}</span>
          </div>
          <div className="d-flex justify-content-center ">
            <Button variant="outline" onClick={clearFile}>
              <FontAwesomeIcon icon={faTrash} className="me-2" />
              <FormattedMessage id="fileUpload.clearButton" />
            </Button>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center ">
          <Button variant="outline" onClick={() => inputRef.current?.click()}>
            <FontAwesomeIcon icon={faFile} className="me-2" />
            <FormattedMessage id="fileUpload.uploadButton" />
          </Button>
        </div>
      )}
    </div>
  );
};

type Props = {
  onChange: (value: FileList | null) => void;
  onClear?: () => void;
  className?: string;
  accept?: string;
};

export default FileUpload;
