import React, { useMemo, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faDownload } from '@fortawesome/pro-regular-svg-icons';
import Spinner from '../../../components/Spinner/Spinner';
import useNotifications from '../../../hooks/useNotifications';
import Header from '../../../components/Header/Header';
import campaignFileService from '../../../services/campaignFileService';
import TableHeader from '../../../components/Table/TableHeader/TableHeader';
import ListFileUploadSortColumn from '../../../models/FileUpload/ListFileUploadSortColumn';
import GenericFormattedDate from '../../../components/GenericFormattedDate/GenericFormattedDate';
import CampaignFileStatus from '../../../models/FileUpload/CampaignFileStatus';
import CampaignFileItem from '../../../models/FileUpload/CampaignFileItem';
import Confirm from '../../../components/Confirm/Confirm';
import DownloadFileDropdownItem from '../../../components/DownloadFileDropdownItem/DownloadFileDropdownItem';
import Select from '../../../components/Select/Select';
import TitleTooltip from '../../../components/Tooltips/TitleTooltip/TitleTooltip';
import useQueryParams from '../../../hooks/useQueryParams';
import ActionsDropdown from '../../../components/ActionsDropdown/ActionsDropdown';
import FileManagementSkeleton from './FileManagementSkeleton';
import './FileManagement.scss';
import companyService from '../../../services/companyService';
import useSortingViewState from '../../../hooks/useSortingViewState';

const pageSize = 20;

const columns: { name: ListFileUploadSortColumn | ''; key: string }[] = [
  { name: 'createdAt', key: 'admin.fileManagement.table.header.createdAt' },
  { name: 'manufacturerCompanyName', key: 'admin.fileManagement.table.header.manufacturerCompanyName' },
  { name: 'productName', key: 'admin.fileManagement.table.header.productName' },
  { name: 'campaignName', key: 'admin.fileManagement.table.header.campaignName' },
  { name: 'fileName', key: 'admin.fileManagement.table.header.fileName' },
  { name: 'type', key: 'admin.fileManagement.table.header.type' },
  { name: 'author', key: 'admin.fileManagement.table.header.author' },
  { name: 'comment', key: 'admin.fileManagement.table.header.comment' },
  { name: 'status', key: 'admin.fileManagement.table.header.status' },
  { name: '', key: '' }, // action
];

const FileManagement = () => {
  const { formatMessage: fm, locale } = useIntl();
  const queryClient = useQueryClient();
  const { notify, notifySuccess, notifyError } = useNotifications();
  const { getSortOrder } = useSortingViewState();
  const [isFileDownloading, setFileDownloading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { companyId, status } = useQueryParams(['companyId', 'status']);
  const setFilters = (value: { companyId?: string; status?: string }) => {
    navigate({
      search: qs.stringify({
        companyId: companyId || undefined,
        status: status || undefined,
        ...value,
      }),
    });
  };

  const {
    data: filesData,
    isLoading: isFilesLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['campaignFiles', getSortOrder<ListFileUploadSortColumn>('createdAt', 'FileManagement', false), companyId, status],
    async ({ pageParam = 1 }) => {
      const data = await campaignFileService.list({
        paging: { pageSize, pageNumber: pageParam },
        orderBy: getSortOrder<ListFileUploadSortColumn>('createdAt', 'FileManagement', false).toString(),
        companyId: companyId || undefined,
        fileStatus: status || undefined,
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          fm({ id: 'admin.fileManagement.notification.title' }),
          fm({ id: 'admin.fileManagement.notification.unableToLoadFiles' }),
        ),
    },
  );

  const [fileToImport, setFileToImport] = useState<CampaignFileItem>();
  const { mutate: importFile, isLoading: isMarkingImported } = useMutation(
    async (id: string) => {
      await campaignFileService.markImported(id);
    },
    {
      onSuccess: async () => {
        notifySuccess(
          fm({ id: 'admin.fileManagement.notification.title' }),
          fm({ id: 'admin.fileManagement.notification.importedFile' }),
        );
        return queryClient.invalidateQueries('campaignFiles');
      },
      onError: () =>
        notifyError(
          fm({ id: 'admin.fileManagement.notification.title' }),
          fm({ id: 'admin.fileManagement.notification.unableToImportFile' }),
        ),
    },
  );
  const onConfirmImport = () => {
    if (!fileToImport) return;

    importFile(fileToImport.id);
    setFileToImport(undefined);
  };

  const { data: companiesModel, isLoading: isLoadingCompanies } = useQuery(['companies'], () => companyService.list(), {
    onError: () =>
      notify(
        'error',
        fm({ id: 'admin.fileManagement.notification.title' }),
        fm({ id: 'admin.fileManagement.notification.unableLoadCompanies' }),
      ),
  });
  const companyOptions = useMemo(() => {
    return [
      { label: fm({ id: 'admin.fileManagement.filter.company.placeholder' }), value: '' },
      ...(companiesModel
        ? companiesModel.companies.map((company) => ({ label: company.name, value: company.id }))
        : []),
    ];
  }, [companiesModel, locale]);
  const fileStatusOptions = useMemo(() => {
    return [
      { label: fm({ id: 'admin.fileManagement.filter.status.placeholder' }), value: '' },
      { label: CampaignFileStatus.New, value: CampaignFileStatus.New },
      { label: CampaignFileStatus.Imported, value: CampaignFileStatus.Imported },
    ];
  }, [companiesModel, locale]);

  return (
    <>
      <Header title={fm({ id: 'admin.fileManagement.title' })} showSearch />
      <div className="file-management-component page-content px-5">
        <div className="filters-container pb-4">
          <Form.Group className="d-flex">
            <div className="me-3">
              <Select
                value={companyId || ''}
                onChange={(value) => setFilters({ companyId: value || undefined })}
                options={companyOptions}
                size="lg"
              />
            </div>
            <div>
              <Select
                onChange={(value) => setFilters({ status: value || undefined })}
                value={status || ''}
                options={fileStatusOptions}
                size="lg"
              />
            </div>
          </Form.Group>
        </div>
        {filesData?.pages?.[0]?.files?.length === 0 && (
          <div className="row p-7">
            <h3 className="col d-flex justify-content-center text-color--white">
              <FormattedMessage id="admin.fileManagement.noData" />
            </h3>
          </div>
        )}
        {isFilesLoading && <FileManagementSkeleton cards={9} columns={columns} />}
        {!!filesData?.pages?.[0]?.files?.length && (
          <div className="mb-4 shadow-2">
            <div className="bg-color-gray-l3 text-uppercase p-3 rounded-top font-bold">
              <FormattedMessage id="admin.fileManagement.totalCount" values={{ count: filesData?.pages[0]?.total }} />
            </div>
            <InfiniteScroll
              className="rounded-bottom"
              next={fetchNextPage}
              hasMore={hasNextPage || false}
              loader={null}
              dataLength={filesData.pages.length}
              scrollableTarget="scrollContainer"
            >
              <table className="file-management-table w-100">
                <TableHeader<ListFileUploadSortColumn> columns={columns} tableName="FileManagement" />
                <tbody className="animated-component-item">
                  {filesData.pages
                    .flatMap((x) => x.files)
                    .map((file) => (
                      <tr key={file.id}>
                        <td className="td-width">
                          <GenericFormattedDate value={file.createdAt} withTime />
                        </td>
                        <td>
                          <TitleTooltip
                            titleTooltip={file.manufacturerCompanyName}
                            title={file.manufacturerCompanyName}
                            titleClassName="inline-crop"
                          />
                        </td>
                        <td>
                          <TitleTooltip
                            titleTooltip={file.productName}
                            title={file.productName}
                            titleClassName="inline-crop"
                          />
                        </td>
                        <td>
                          <TitleTooltip
                            titleTooltip={file.campaignName}
                            title={file.campaignName}
                            titleClassName="inline-crop"
                          />
                        </td>
                        <td className="td-width">
                          <div className="td-width-file-name">
                            <TitleTooltip
                              titleTooltip={file.fileName}
                              title={file.fileName}
                              titleClassName="inline-crop"
                            />
                          </div>
                        </td>
                        <td>
                          <TitleTooltip titleTooltip={file.type} title={file.type} titleClassName="inline-crop" />
                        </td>
                        <td>
                          <TitleTooltip titleTooltip={file.author} title={file.author} titleClassName="inline-crop" />
                        </td>
                        <td className="td-width">
                          <div className="td-width-comment">
                            <TitleTooltip
                              titleTooltip={file.comment}
                              title={file.comment}
                              titleClassName="inline-crop"
                            />
                          </div>
                        </td>
                        <td>
                          <TitleTooltip titleTooltip={file.status} title={file.status} titleClassName="inline-crop" />
                        </td>
                        <td align="right">
                          {file.status === CampaignFileStatus.New && (
                            <ActionsDropdown>
                              <Dropdown.Item
                                className="text-uppercase d-flex align-items-center"
                                onClick={() => setFileToImport(file)}
                              >
                                <FontAwesomeIcon icon={faCheck} className="pe-3" />
                                <FormattedMessage id="admin.fileManagement.action.import" />
                              </Dropdown.Item>
                              <DownloadFileDropdownItem
                                downloadFile={() => campaignFileService.download(file.id)}
                                onLoadingChange={setFileDownloading}
                              >
                                <FontAwesomeIcon icon={faDownload} className="pe-3" />
                                <FormattedMessage id="admin.fileManagement.action.download" />
                              </DownloadFileDropdownItem>
                            </ActionsDropdown>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        )}
        {fileToImport && (
          <Confirm
            title={fm({ id: 'admin.fileManagement.confirm.import.title' })}
            targetName={fileToImport.fileName}
            confirmTitle={fm({ id: 'admin.fileManagement.confirm.import.confirmMessage' })}
            message={fm({ id: 'admin.fileManagement.confirm.import.message' })}
            onClose={() => setFileToImport(undefined)}
            onConfirm={() => onConfirmImport()}
          />
        )}
        {(isMarkingImported || isFileDownloading || isLoadingCompanies) && <Spinner />}
      </div>
    </>
  );
};

export default FileManagement;
