import { faChartPie } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Accordion } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import numberHelper from '../../helpers/numberHelper';
import productsHelper from '../../helpers/productsHelper';
import ProblemTrackerFilter from '../../models/ProblemTrackerFilter';
import EntityTypes from '../../models/Report/EntityTypes';
import ReportData from '../../models/Report/ReportData';
import SynthesisStep from '../../models/SynthesisStep/SynthesisStep';
import EventKey from '../../models/SynthesisStep/SynthesisStepAccordionKey';
import SynthesisStepViewState from '../../models/SynthesisStep/SynthesisStepViewState';
import TrackersTabs from '../../models/Tabs/TrackersTabs';
import AccordionItem from '../Accordion/AccordionToggle/AccordionItem/AccordionItem';
import AccordionLazyContent from '../Accordion/AccordionToggle/AccordionLazy/AccordionLazyContent';
import AccordionToggle from '../Accordion/AccordionToggle/AccordionToggle';
import GenericFormattedDate from '../GenericFormattedDate/GenericFormattedDate';
import Progress from '../Progress/Progress';
import ReportButton from '../Report/ReportButton';
import SynthesisStepTrackers from '../SynthesisStepTrackers/SynthesisStepTrackers';
import OutOfRange from '../trackers/OutOfRange/OutOfRange';
import OutOfSpecification from '../trackers/OutOfSpecification/OutOfSpecification';
import OutOfTime from '../trackers/OutOfTime/OutOfTime';

interface Props {
  step: SynthesisStep;
  showBtnGetReport?: boolean;
  viewState?: SynthesisStepViewState;
  onViewStateChange: (state?: SynthesisStepViewState) => void;
  isScrollIntoView?: boolean;
  linkToDetails?: string;
}

const SynthesisStepItem: FC<Props> = ({
  step,
  showBtnGetReport = true,
  viewState,
  onViewStateChange,
  isScrollIntoView,
  linkToDetails,
}) => {
  const intl = useIntl();
  const reportData: ReportData = useMemo(() => {
    return {
      productName: step.productName,
      synthesisStepName: step.name,
      entityType: EntityTypes.synthesisStep,
      entityId: step.id,
    };
  }, [step]);

  const headerRef = useRef<HTMLDivElement>(null);
  const trackersRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isScrollIntoView) {
      return;
    }

    let ref: HTMLDivElement | null = null;

    if (viewState?.accordion === EventKey.trackers) {
      ref = trackersRef.current;
    } else {
      ref = headerRef.current;
    }

    ref?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, []);

  const activeKey = viewState?.accordion;

  const handleAccordionToggle = (eventKey: EventKey.trackers) => {
    const newViewState =
      viewState?.accordion === eventKey
        ? undefined
        : {
            accordion: eventKey,
          };

    onViewStateChange(newViewState);
  };

  const handleTrackersTabChange = (tab: TrackersTabs) => {
    onViewStateChange({
      accordion: EventKey.trackers,
      tab,
    });
  };

  return (
    <div className="card mb-4" data-test-id="synthesisStep-item">
      <div ref={headerRef} className="d-flex p-3 card-divider align-items-center gap-3">
        <h3 className="font-bold text-color-white text-uppercase mb-0 me-5" data-test-id="synthesisStep-item-name">
          {step.name}
        </h3>
        <div className="py-1 px-2 d-flex gap-2 bg-color-gray-l2 rounded-1 align-items-center">
          <div className="size-caption text-color-gray-l6">
            <FormattedMessage id="synthesisStep.step.totalCampaigns" />
          </div>
          <div className="size-label" data-test-id="synthesisStep-item-totalCampaings">
            {numberHelper.formatNumber(step.totalCampaigns)}
          </div>
        </div>
        <div className="py-1 px-2 d-flex gap-2 bg-color-gray-l2 rounded-1 align-items-center me-auto">
          <div className="size-caption text-color-gray-l6">
            <FormattedMessage id="synthesisStep.step.totalBatches" />
          </div>
          <div className="size-label" data-test-id="synthesisStep-item-totalBatches">
            {numberHelper.formatNumber(step.totalBatches)}
          </div>
        </div>
        {showBtnGetReport && <ReportButton reportData={reportData} />}
        <Link
          to={linkToDetails ?? productsHelper.getCampaignsUrl({ productId: step.productId, stepId: step.id })}
          className="btn btn-primary btn-sm"
        >
          <FormattedMessage id="synthesisStep.step.showCampaigns" />
        </Link>
      </div>
      <div className="p-3 card-divider d-flex align-items-center gap-3">
        <div className="size-label font-bold me-auto">
          <FormattedMessage id="synthesisStep.step.manufacturer" />{' '}
          <span data-test-id="synthesisStep-item-manufacturer">{step.manufacturerCompanyName}</span>
        </div>
        <div className="size-label text-color-gray-l6">
          <FormattedMessage id="synthesisStep.step.phase" />{' '}
          <span data-test-id="synthesisStep-item-phase">{step.phaseName}</span>
        </div>
        <div className="size-label text-color-gray-l6">
          <FormattedMessage id="synthesisStep.step.updatedAt" />{' '}
          <span data-test-id="synthesisStep-item-updatedAt">
            <GenericFormattedDate value={step.updatedAt} />
          </span>
        </div>
      </div>
      <div className="d-flex gap-3 px-3 py-3 gx-3 card-divider">
        <div className="flex-2 chart-row">
          <Progress value={step.progress} />
        </div>
        <div className="overflow-auto flex-2">
          <OutOfRange
            value={step.outOfRange}
            columns={step.outOfRangeList}
            tooltip={intl.formatMessage({ id: 'tracker.outOfRange.tooltips.synthesisStep' })}
            link={productsHelper.getBatchesUrl({
              productId: step.productId,
              synthesisStepId: step.id,
              problemTracker: ProblemTrackerFilter.OutOfRange,
            })}
            getBatchUrl={(batchId: string) =>
              productsHelper.getBatchesUrl({
                productId: step.productId,
                synthesisStepId: step.id,
                synthesisStepName: step.name,
                viewState: {
                  idsFilter: [batchId],
                },
              })
            }
          />
        </div>
        <div className="flex-1">
          <OutOfSpecification
            numerator={step?.outOfSpecificationNumerator}
            denominator={step?.outOfSpecificationDenominator}
            tooltip={intl.formatMessage({ id: 'tracker.outOfSpecification.tooltips.synthesisStep' })}
            link={productsHelper.getBatchesUrl({
              productId: step.productId,
              synthesisStepId: step.id,
              problemTracker: ProblemTrackerFilter.OutOfSpecification,
            })}
          />
        </div>
        <div className="flex-1">
          <OutOfTime
            delay={step.outOfTime}
            status={step.outOfTimeStatus}
            tooltip={intl.formatMessage({ id: 'tracker.outOfTime.tooltips.synthesisStep' })}
            link={productsHelper.getBatchesUrl({
              productId: step.productId,
              synthesisStepId: step.id,
              problemTracker: ProblemTrackerFilter.OutOfTime,
            })}
          />
        </div>
      </div>
      <Accordion activeKey={activeKey ?? null}>
        <AccordionItem eventKey={EventKey.trackers} dataTestId="synthesisStep-item-trackers">
          <div ref={trackersRef} className="header card-divider px-4 py-3">
            <AccordionToggle
              eventKey={EventKey.trackers}
              dataTestId="synthesisStep-item-trackers-toggle"
              callback={handleAccordionToggle}
            >
              <div className="d-flex align-self-center">
                <FontAwesomeIcon icon={faChartPie} className="pe-3 d-flex align-self-center" />
                <span className="text-uppercase font-bold accordion-item-title">
                  <FormattedMessage id="synthesisStepTrackers.title" />
                </span>
              </div>
            </AccordionToggle>
          </div>
          <Accordion.Collapse eventKey={EventKey.trackers}>
            <AccordionLazyContent eventKey={EventKey.trackers}>
              <SynthesisStepTrackers
                stepId={step.id}
                activeTab={viewState?.accordion === EventKey.trackers ? (viewState!.tab as TrackersTabs) : undefined}
                onTabChange={handleTrackersTabChange}
              />
            </AccordionLazyContent>
          </Accordion.Collapse>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default SynthesisStepItem;
