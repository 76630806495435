import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import numberHelper from '../../../helpers/numberHelper';
import trackerHelper from '../../../helpers/trackerHelper';
import SynthesisStepTrackers from '../../../models/SynthesisStep/SynthesisStepTrackers';
import CircleValueTracker from '../../trackers/CircleValueTracker/CircleValueTracker';
import ValueTracker from '../../trackers/ValueTracker/ValueTracker';
import DistributionOfImpurities from '../../trackers/DistributionOfImpurities/DistributionOfImpurities';

const QualityTab: FC<Props> = ({ trackers }) => {
  const intl = useIntl();
  return (
    <div className="row g-3">
      <div className="col-3 chart-row">
        <CircleValueTracker
          variant={trackerHelper.getTypeForProgress(trackers?.yield)}
          title={intl.formatMessage({ id: 'tracker.yield.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.yield.tooltips.synthesisStep' })}
          value={trackers?.yield}
        />
      </div>
      <div className="col-3 chart-row">
        <CircleValueTracker
          variant={trackerHelper.getTypeForProgress(trackers?.conversion)}
          title={intl.formatMessage({ id: 'tracker.conversion.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.conversion.tooltips.synthesisStep' })}
          value={trackers?.conversion}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.sumOfImpurities)}
          title={intl.formatMessage({ id: 'tracker.sumOfImpurities.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.sumOfImpurities.tooltips.synthesisStep' })}
          value={numberHelper.formatNumber(trackers?.sumOfImpurities)?.toString()}
          hint={trackers?.sumOfImpuritiesUnit}
          method={trackers?.sumOfImpuritiesMethod}
          units="%"
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.assay)}
          title={intl.formatMessage({ id: 'tracker.assay.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.assay.tooltips.synthesisStep' })}
          value={numberHelper.formatNumber(trackers?.assay)?.toString()}
          units="%"
          hint={intl.formatMessage({ id: 'tracker.assay.hint' })}
          method={trackers?.assayMethod}
        />
      </div>
      <div className="col-3 chart-row">
        <DistributionOfImpurities
          tooltip={intl.formatMessage({ id: 'tracker.distributionOfImpurities.tooltips.synthesisStep' })}
          value={trackers?.distributionOfImpurities || undefined}
          unit={` ${trackers?.distributionOfImpuritiesUnit}`}
          method={trackers?.distributionOfImpuritiesMethod}
        />
      </div>
    </div>
  );
};

interface Props {
  trackers?: SynthesisStepTrackers;
}

export default QualityTab;
