import React, { useEffect, useMemo, useState } from 'react';
import { StylesConfig, components } from 'react-select';
import ReactAsyncSelect from 'react-select/async';
import SelectOption from '../../../models/SelectOption';
import defaultStyles from '../styles';

const createCustomContainer = (props: any, dataTestId?: string) => (
  <components.SelectContainer {...props} innerProps={{ ...props.innerProps, ...{ 'data-test-id': dataTestId } }} />
);

const AsyncSelect: React.FC<Props> = ({
  onChange,
  onSearch,
  options,
  dataTestId,
  placeholder,
  value,
  noOptionsMessage,
  isError,
  isDisabled,
  size,
  defaultOptions,
}) => {
  const styles = useMemo(() => {
    const base = size === 'lg' ? defaultStyles.lgStyles : defaultStyles.stylesBase;

    if (isError) {
      const baseWithTheme: StylesConfig<SelectOption> = {
        ...base,
        control: (provided, state) => ({
          ...base.control!(provided, state),
          borderColor: '#F04141',
          ':hover': {
            borderColor: '#F04141',
          },
        }),
      };

      return baseWithTheme;
    }

    return base;
  }, [size, isError]);

  const customComponents = useMemo(
    () => ({
      SelectContainer: (props: any) => createCustomContainer(props, dataTestId),
    }),
    [dataTestId],
  );

  const [searchCallback, setSearchCallback] = useState<(options: SelectOption[]) => void>();
  const loadOptions = (term: string, callback: (options: SelectOption[]) => void) => {
    setSearchCallback(() => callback);
    onSearch(term);
  };

  useEffect(() => {
    if (searchCallback && options) {
      searchCallback(options);
    }
  }, [searchCallback, options]);

  return (
    <ReactAsyncSelect<SelectOption>
      defaultOptions={defaultOptions}
      components={customComponents}
      styles={styles}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      loadOptions={loadOptions}
      onChange={(v) => onChange(v!)}
      value={value}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage ? () => noOptionsMessage : undefined}
      isDisabled={isDisabled}
    />
  );
};

interface Props {
  onChange(value: SelectOption): void;
  onSearch(term: string): void;
  options?: SelectOption[];
  value?: SelectOption[];
  isDisabled?: boolean;
  dataTestId?: string;
  placeholder?: string;
  noOptionsMessage?: string;
  isError?: boolean;
  size?: 'sm' | 'lg';
  defaultOptions?: SelectOption[] | boolean;
}

export default AsyncSelect;
