import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import BatchTrackers from '../../../models/Batch/BatchTrackers';
import ValueTracker from '../../trackers/ValueTracker/ValueTracker';
import numberHelper from '../../../helpers/numberHelper';
import trackerHelper from '../../../helpers/trackerHelper';
import CircleValueTracker from '../../trackers/CircleValueTracker/CircleValueTracker';

const PerformanceTab: FC<Props> = ({ trackers }) => {
  const intl = useIntl();
  return (
    <div className="row g-3">
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.weight)}
          title={intl.formatMessage({ id: 'tracker.weight.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.weight.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.weight)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.weight.unit' })}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.percentageTargetYield)}
          title={intl.formatMessage({ id: 'tracker.percentageTargetYield.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.percentageTargetYield.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.percentageTargetYield)?.toString()}
          units="%"
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.percentageGoldenBatchYield)}
          title={intl.formatMessage({ id: 'tracker.percentageGoldenBatchYield.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.percentageGoldenBatchYield.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.percentageGoldenBatchYield)?.toString()}
          units="%"
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.productionRate)}
          title={intl.formatMessage({ id: 'tracker.productionRate.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.productionRate.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.productionRate)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.productionRate.unit' })}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.spaceTimeYield)}
          title={intl.formatMessage({ id: 'tracker.spaceTimeYield.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.spaceTimeYield.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.spaceTimeYield)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.spaceTimeYield.unit' })}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.plantHourConsumption)}
          title={intl.formatMessage({ id: 'tracker.plantHourConsumption.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.plantHourConsumption.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.plantHourConsumption)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.plantHourConsumption.unit' })}
        />
      </div>
      <div className="col-3 chart-row">
        <CircleValueTracker
          variant={trackerHelper.getTypeForProgress(trackers?.utilizedCapacity)}
          title={intl.formatMessage({ id: 'tracker.utilizedCapacity.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.utilizedCapacity.tooltips.batch' })}
          value={trackers?.utilizedCapacity}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.cycleTime)}
          title={intl.formatMessage({ id: 'tracker.cycleTime.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.cycleTime.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.cycleTime)?.toString()}
          hint={intl.formatMessage(
            { id: 'tracker.cycleTime.unit' },
            { hours: numberHelper.formatNumber(trackers?.cycleTime) },
          )}
        />
      </div>
    </div>
  );
};

interface Props {
  trackers?: BatchTrackers;
}

export default PerformanceTab;
