import React, { FC, useEffect, useMemo, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { string, object, mixed } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button, Form, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { transformString } from '../../../helpers/yupExtentions';
import Shape from '../../../models/Shape';
import useNotifications from '../../../hooks/useNotifications';
import Regex from '../../../constants/regex';
import Spinner from '../../../components/Spinner/Spinner';
import campaignFileService from '../../../services/campaignFileService';
import campaignService from '../../../services/campaignService';
import productService from '../../../services/productService';
import CampaignFileType from '../../../models/FileUpload/CampaignFileType';
import AsyncSelect from '../../../components/Select/AsyncSelect/AsyncSelect';
import SelectOption from '../../../models/SelectOption';
import Select from '../../../components/Select/Select';
import FileUpload from '../../../components/FileUpload/FileUpload';

const UploadFileModal: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const [fileType, setFileType] = useState<CampaignFileType>();
  const acceptFiles = useMemo(() => {
    switch (fileType) {
      case CampaignFileType.Other:
        return 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf';
      default:
        return 'text/csv,application/vnd.ms-excel';
    }
  }, [fileType]);

  const validationSchema = useMemo(() => {
    return object<Shape<FormData>>({
      productId: object<Shape<SelectOption>>({
        value: string()
          .transform(transformString)
          .required(intl.formatMessage({ id: 'validation.required' })),
      }),
      campaignId: object<Shape<SelectOption>>({
        value: string()
          .transform(transformString)
          .required(intl.formatMessage({ id: 'validation.required' })),
      }),
      type: string().required(intl.formatMessage({ id: 'validation.required' })),
      comment: string()
        .transform(transformString)
        .max(500, intl.formatMessage({ id: 'validation.maxLength' }, { value: 500 }))
        .matches(Regex.NameExtended, intl.formatMessage({ id: 'validation.nameExtendedRegex' })),
      file: mixed<FileList>()
        .test('required', intl.formatMessage({ id: 'validation.required' }), (files) => files && files.length > 0)
        .test('fileSize', intl.formatMessage({ id: 'validation.fileSize' }, { value: 100 }), (files) => {
          return !files || !files.length || files[0].size < 1024 * 1024 * 100;
        })
        .test('fileType', intl.formatMessage({ id: 'validation.fileType' }), (files) => {
          return !files || !files.length || acceptFiles.indexOf(files[0].type) >= 0;
        }),
    });
  }, [intl.locale, acceptFiles]);

  const {
    register,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
  } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });
  const queryClient = useQueryClient();

  const { mutate: uploadFile, isLoading: isSaving } = useMutation(
    async (formData: FormData) => {
      await campaignFileService.upload(formData.campaignId!.value, formData.type, formData.file, formData.comment);
      return formData;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('campaignFiles');
        notify(
          'success',
          intl.formatMessage({ id: 'fileUploadList.form.notification.title' }),
          intl.formatMessage({
            id: 'fileUploadList.form.notification.uploadedFile',
          }),
        );
        onClose(true);
      },
      onError: () => {
        notify(
          'error',
          intl.formatMessage({ id: 'fileUploadList.form.notification.title' }),
          intl.formatMessage({ id: 'fileUploadList.form.notification.unableToUploadFile' }),
        );
      },
    },
  );

  const [productId, setProductId] = useState<string>();
  const selectProduct = (option: SelectOption, onChange: (value: SelectOption) => void) => {
    setProductId(option.value);
    onChange(option);
  };
  const [productTerm, setProductTerm] = useState<string>('');
  const { data: productsData, isLoading: isLoadingProducts } = useQuery(
    ['products', productId, productTerm],
    async () => {
      const data = await productService.manufacturerList(productTerm === '' ? undefined : productTerm, {
        pageNumber: 1,
        pageSize: 7,
      });
      return data.products.map((item) => ({ label: item.name, value: item.id }));
    },
    {
      onError: () => {
        notify(
          'error',
          intl.formatMessage({ id: 'fileUploadList.form.notification.title' }),
          intl.formatMessage({ id: 'fileUploadList.form.notification.unableToLoadProducts' }),
        );
      },
    },
  );
  const [campaignTerm, setCampaignTerm] = useState<string>('');
  const { data: campaignsData, isLoading: isLoadingCampaigns } = useQuery(
    ['campaigns', productId, campaignTerm],
    async () => {
      const data = await campaignService.manufacturerList(productId, campaignTerm === '' ? undefined : campaignTerm, {
        pageNumber: 1,
        pageSize: 7,
      });
      return data.campaigns.map((item) => ({ label: item.name, value: item.id }));
    },
    {
      enabled: !!productId,
      onError: () => {
        notify(
          'error',
          intl.formatMessage({ id: 'fileUploadList.form.notification.title' }),
          intl.formatMessage({ id: 'fileUploadList.form.notification.unableToLoadCampaigns' }),
        );
      },
    },
  );
  useEffect(() => {
    setValue('campaignId', undefined);
  }, [productId]);

  const fileTypeOptions = useMemo(
    () => [
      {
        value: CampaignFileType.ProcessData,
        label: intl.formatMessage({ id: 'fileUploadList.form.field.type.option.processData' }),
      },
      {
        value: CampaignFileType.Other,
        label: intl.formatMessage({ id: 'fileUploadList.form.field.type.option.other' }),
      },
    ],
    [intl.locale],
  );
  const selectFileType = (value: string | undefined, onChange: (value?: string) => void) => {
    setFileType(value as CampaignFileType);
    onChange(value);
  };

  const isLoading = isSubmitting || isSaving || isLoadingProducts || isLoadingCampaigns;

  return (
    <>
      <Modal show centered>
        <span className="text-uppercase text-color-primary-green size-label px-4 pt-4">
          <FormattedMessage id="fileUploadList.form.extraTitle" />
        </span>
        <Modal.Header className="px-4">
          <h3>
            <FormattedMessage id="fileUploadList.form.title" />
          </h3>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Form className="d-flex flex-column" noValidate onSubmit={handleSubmit((data) => uploadFile(data))}>
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="fileUploadList.form.field.product.label" />
              </Form.Label>
              <Controller
                control={control}
                name="productId"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <AsyncSelect
                    options={productsData}
                    onSearch={setProductTerm}
                    value={[value!]}
                    onChange={(option) => selectProduct(option, onChange)}
                    placeholder={intl.formatMessage({ id: 'fileUploadList.form.field.product.placeholder' })}
                    isError={!!error}
                    defaultOptions
                  />
                )}
              />
              {!!errors?.productId && (
                <Form.Control.Feedback type="invalid">{errors.productId?.value?.message}</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="fileUploadList.form.field.campaign.label" />
              </Form.Label>
              <Controller
                control={control}
                name="campaignId"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <AsyncSelect
                    options={campaignsData}
                    onSearch={setCampaignTerm}
                    value={[value!]}
                    onChange={onChange}
                    placeholder={intl.formatMessage({ id: 'fileUploadList.form.field.campaign.placeholder' })}
                    isError={!!error}
                    isDisabled={!getValues('productId')?.value}
                    defaultOptions={campaignsData}
                  />
                )}
              />
              {!!errors?.campaignId && (
                <Form.Control.Feedback type="invalid">{errors.campaignId?.value?.message}</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="fileUploadList.form.field.type.label" />
              </Form.Label>
              <Controller
                control={control}
                name="type"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    options={fileTypeOptions}
                    value={value}
                    onChange={(v) => selectFileType(v, onChange)}
                    placeholder={intl.formatMessage({ id: 'fileUploadList.form.field.type.placeholder' })}
                    isError={!!error}
                  />
                )}
              />
              {!!errors?.type && <Form.Control.Feedback type="invalid">{errors.type.message}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>
                <FormattedMessage id="fileUploadList.form.field.file.label" />
              </Form.Label>
              <Controller
                control={control}
                name="file"
                render={({ field: { onChange } }) => (
                  <FileUpload
                    onChange={(files) => onChange(files)}
                    onClear={() => onChange(null)}
                    accept={acceptFiles}
                  />
                )}
              />
              {!!errors?.file && <Form.Control.Feedback type="invalid">{errors.file.message}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="fileUploadList.form.field.comment.label" />
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder={intl.formatMessage({ id: 'fileUploadList.form.field.comment.placeholder' })}
                {...register('comment')}
                isInvalid={!!errors?.comment}
              />
              {!!errors?.comment && (
                <Form.Control.Feedback type="invalid">{errors.comment.message}</Form.Control.Feedback>
              )}
            </Form.Group>
            <Button variant="primary" type="submit" className="btn btn-primary mb-3 mt-4" disabled={isLoading}>
              <FormattedMessage id="fileUploadList.form.action.upload" />
            </Button>
            <Button variant="ghost" type="button" className="btn" onClick={() => onClose(false)} disabled={isLoading}>
              <FormattedMessage id="fileUploadList.form.action.cancel" />
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {isLoading && <Spinner />}
    </>
  );
};

interface Props {
  onClose: (update: boolean) => void;
}

type FormData = {
  productId?: SelectOption;
  campaignId?: SelectOption;
  comment: string | null;
  file: FileList;
  type: CampaignFileType;
};

export default UploadFileModal;
